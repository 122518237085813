
import { defineComponent } from 'vue';
import slugify from 'slugify';
import UiErrors from '@/components/form/UiErrors.vue';
import InventoryService from '@/services/InventoryService';
import UiTextField from '@/components/form/UiTextField.vue';
import MediaManager from '@/components/modals/MediaManager.vue';
import UiTextEditor from '@/components/form/UiTextEditor.vue';
import UiButton from '@/components/form/UiButton.vue';

export default defineComponent({
  name: 'CompanyCreate',
  data: () => ({
    isProcessing: false,
    errors: {} as any,
    company: {
      description: '',
      details: {
        address: {},
        company_contact: [],
        contacts: [],
        images: [],
      },
      short_code: '',
      title: '',
    } as any,
  }),
  components: {
    UiErrors,
    UiTextField,
    MediaManager,
    UiTextEditor,
    UiButton,
  },
  methods: {
    transformCompanyForSave(model: any) {
      slugify.extend({
        '+': '-',
        '-': '-',
        ',': '-',
        '.': '-',
        '>': '-',
        '<': '-',
        '&': '-',
        '/': '-',
      });

      return {
        code: model.short_code,
        description: model.description,
        details: {
          address: {
            country: model.details.address.country,
            region_postcode: model.details.address.region_postcode,
            city: model.details.address.city,
            street_appartment: model.details.address.street_appartment,
          },
          company_contact: {
            cvrnr: model.details.company_contact.cvrnr,
            email: model.details.company_contact.email,
            telephone: model.details.company_contact.telephone,
            website: model.details.company_contact.website,
          },
          contacts: model.details.contacts.map((el: any) => ({
            topic: el.topic,
            direct: el.direct,
            email: el.email,
            full_name: el.full_name,
            mobile: el.mobile,
          })),
          images: model.details.images.map((el: any) => ({
            path: el.path,
            description: el.description,
            link: el.link?.uri
              ? {
                uri: el.link.uri,
                text: '',
              }
              : '',
            default: el.default,
          })),
        },
        short_code: model.short_code,
        title: model.title,
        slug: slugify(model.title, {
          replacement: '-',
          lower: true,
          strict: true,
        }),
      };
    },
    addContact() {
      this.company.details.contacts.push({
        topic: '',
        direct: '',
        email: '',
        full_name: '',
        mobile: '',
      });
    },
    removeContact(i: number) {
      this.$nextTick(() => this.company.details.contacts.splice(i, 1));
    },
    isValidPhone(value: string) {
      return /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g.test(value);
    },
    isValidEmail(value: string) {
      return String(value)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
    },
    isValidUrl(value: string) {
      return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm.test(value);
    },
    async createCompany() {
      try {
        this.isProcessing = true;
        this.errors = {};

        if (!this.company.title) {
          this.errors.title = ['Title is required'];
        }

        if (!this.company.short_code) {
          this.errors.short_code = ['Short name is required'];
        }

        // address

        if (!this.company.details.address.country) {
          this.errors.country = ['Country is required'];
        }

        if (!this.company.details.address.region_postcode) {
          this.errors.region_postcode = ['Region postcode is required'];
        }

        if (!this.company.details.address.city) {
          this.errors.city = ['City is required'];
        }

        if (!this.company.details.address.street_appartment) {
          this.errors.street_appartment = ['Street appartment is required'];
        }

        // company contact

        if (!this.company.details.company_contact.cvrnr) {
          this.errors.cvrnr = ['CVR is required'];
        }

        if (!this.company.details.company_contact.email) {
          this.errors.email = ['Email is required'];
        }

        if (!this.isValidEmail(this.company.details.company_contact.email)) {
          this.errors.email = [
            ...(this.errors.email || []),
            ...['Email is not valid'],
          ];
        }

        if (!this.company.details.company_contact.telephone) {
          this.errors.telephone = ['Telephone is required'];
        }

        if (!this.isValidPhone(this.company.details.company_contact.telephone)) {
          this.errors.telephone = [
            ...(this.errors.telephone || []),
            ...['Telephone is not valid'],
          ];
        }

        if (!this.company.details.company_contact.website) {
          this.errors.website = ['Website is required'];
        }

        if (!this.isValidUrl(this.company.details.company_contact.website)) {
          this.errors.website = [
            ...(this.errors.website || []),
            ...['Website is not valid'],
          ];
        }

        // contacts

        this.company.details.contacts.forEach((contact: any, i: any) => {
          if (!contact.topic) {
            this.errors[`topic_${i}`] = ['Topic is required'];
          }

          if (!contact.direct) {
            this.errors[`direct_${i}`] = ['Direct is required'];
          }

          if (!contact.email) {
            this.errors[`email_${i}`] = ['Email is required'];
          }

          if (!this.isValidEmail(contact.email)) {
            this.errors[`email_${i}`] = [
              ...(this.errors[`email_${i}`] || []),
              ...['Email is not valid'],
            ];
          }

          if (!contact.full_name) {
            this.errors[`full_name_${i}`] = ['Full name is required'];
          }

          if (!contact.mobile) {
            this.errors[`mobile_${i}`] = ['Mobile is required'];
          }

          if (!this.isValidPhone(contact.mobile)) {
            this.errors[`mobile_${i}`] = [
              ...(this.errors[`mobile_${i}`] || []),
              ...['Mobile is not valid'],
            ];
          }
        });

        if (Object.keys(this.errors).length) {
          this.isProcessing = false;
          this.$toast.show('Please enter correct data', 3000);
          return;
        }

        const specs = this.transformCompanyForSave(this.company);

        await InventoryService.producerCreate(specs);

        this.$router.push({
          name: 'CompanyShow',
          params: { id: specs.slug },
        });

        this.isProcessing = false;
      } catch (error: any) {
        this.isProcessing = false;

        if (
          error?.response?.status === 422
          || error?.response?.status === 404
          || error?.response?.status === 409
        ) {
          this.$toast.show(error.response.data?.error?.message, 3000);
        } else {
          this.$toast.show('Server error', 3000);
        }
      }
    },
  },
});
